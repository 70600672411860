<template>
  <div style="background-color: #FFF; min-height: 100vh">
    <div class="text-xs-center" v-if="loading_inicial">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading_inicial">
       <v-container fluid grid-list-md fill-height>
        <v-layout row wrap align-center justify-center fill-height>
          <!-- Web -->
          <v-flex xs12 sm12 md6 class="hidden-sm-and-down align-center justify-center" style="text-align: center; background: #efefef; padding: 3%; min-height: 100vh;">
            <!-- Right WEB -->
            <cpnHeaderWeb class="hidden-sm-and-down"/>
            <v-flex xs12 sm12 md12>
              <div class="header hidden-sm-and-down pt-5">
                <span
                  class="display-1 px-5 py-1 font-weight-bold teal white--text hidden-sm-and-down"
                >Quase lá!</span>
                <div class="headline teal--text pb-4 pt-4 hidden-sm-and-down">
                  Você acaba de dar um importante passo para economizar e ajudar o planeta.
                  <br />
                </div>
                <div
                  style="padding: 20px 10px 20px 75px; margin-left: -75px;  border-radius: 0 10px 10px 0"
                  class="headline white teal--text hidden-sm-and-down"
                >
                  Para atender as normas da Aneel
                  <br />precisamos de mais alguns dados.
                </div>
              </div>
            </v-flex>
          </v-flex>
          <!-- Mobile -->
          <v-flex xs12 sm12 md6 class="hidden-md-and-up" style="text-align: center; background: #fff; padding: 3%;">
            <!-- Header Mobile -->
            <cpnHeaderMobile class="hidden-md-and-up" :hasDuvidas="false"/>
          </v-flex>
          <v-flex d-flex xs12 sm12 md6 class="pt-3" style="text-align: center;">
            <div class="form_conta" style="padding-top: 0px">
              <div class style="padding: 0">
                <!-- Mobile -->
                <cpnSteps class="hidden-md-and-up pt-2" :steps="{steps: 4, e1: 3}"/>
                <div
                  class="title hidden-md-and-up"
                  style="padding-bottom: 15px; color: #808080"
                >Para atender as normas da Aneel precisamos de mais alguns dados</div>
                <div
                  class="red--text body-2 hidden-sm-and-up"
                >Os dados a seguir devem ser do titular da conta de energia.</div>
                <!-- Fim Mobile -->
                <!-- WEB -->
                <cpnSteps  class="hidden-sm-and-down pt-2 pb-3" :steps="{steps: 4, e1: 3}"/>
                <!-- <div
                  class="gray--text hidden-sm-and-down"
                >DADOS COMPLEMENTARES</div> -->
                <div
                    class="display-1 hidden-sm-and-down"
                    style="padding-bottom: 15px; color: #808080"
                  >DADOS COMPLEMENTARES</div>
                <div
                  class="red--text hidden-sm-and-down"
                >Os dados a seguir devem ser do titular da conta de energia.</div>
                <!-- Fim Web -->
                <br />
                <v-form ref="form" lazy-validation class="form_class">
                  <!-- Profissao -->
                  <v-flex xs12>
                    <v-text-field
                      v-model="profissao"
                      label="Profissão do titular da conta de energia"
                      name="profissao"
                      :rules="profissaoRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- identidade -->
                  <v-flex xs12 v-if="!isIdentidade">
                    <v-text-field
                      v-model="identidade"
                      label="Identidade do titular da conta de energia"
                      name="identidade"
                      :rules="identidadeRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- data de nascimento -->
                  <v-flex xs12 v-if="!isDate_of_birth">
                    <v-text-field
                      v-model="date_of_birth"
                      label="Data de nascimento do titular da conta de energia"
                      mask="##/##/####"
                      name="date_of_birth"
                      :rules="[v => !!v || 'Informe a data de nascimento']"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- genero -->
                  <v-flex xs12 v-if="!isGender">
                    <header style="padding-bottom: 4px;  text-align: left">Sexo do titular da conta?</header>

                    <v-radio-group
                      v-model="gender"
                      :rules="[v => !!v || 'Informe o sexo do titular da conta']"
                      row
                    >
                      <v-radio style="margin-right: 8px" label="Masculino" value="M"></v-radio>
                      <v-radio label="Feminino" value="F"></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <!-- Estado civil -->
                  <v-flex xs12>
                    <header
                      style="padding-bottom: 4px; padding-left: 3px; text-align: left"
                    >Estado civil do titular da conta?</header>
                    <v-radio-group
                      v-model="estadoCivil"
                      row
                      @click="deselecionaRegimeDeBens()"
                      :rules="[v => !!v || 'Por favor, informe o estado civil do titular da conta!']"
                    >
                      <v-radio
                        v-for="(ec, index) in estadosCivis"
                        style="margin-right: 3px"
                        :key="index"
                        :label="ec"
                        :value="ec"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <!-- Comunhao de bens -->
                  <v-flex xs12>
                    <header
                      v-if="estadoCivil === 'Casado(a)'"
                      style="text-align: left; padding-bottom: 4px; padding-left: 3px"
                    >Qual Regime de Bens é casado(a). Comunhão:</header>
                    <v-radio-group v-if="estadoCivil === 'Casado(a)'" v-model="comunhaoDeBens">
                      <v-radio
                        v-for="(rdb, index) in regimeDeBens"
                        style="padding-right: 4px"
                        :key="index"
                        :label="rdb"
                        :value="rdb"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <div style="color: red; text-align: left">{{ alertMessage }}</div>
                  <div class="my-2">
                    <v-btn
                      :loading="loading"
                      block
                      large
                      style="background-color: teal; font-weight: bold"
                      @click.prevent="validate()"
                    >Continuar</v-btn>
                  </div>
                </v-form>
                <br />
              </div>
              <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { STORE_USER } from "../../graphql/users"
import { mapGetters } from "vuex"
import { saveAnalytics } from "@/services/save_analytics"
import { SET_CONVERSION } from "../../graphql/rdstation"

// import { setRDConversion } from "@/services/rdstation";
import moment from "moment"
import HeaderWeb from "../../components/web/Header"
import HeaderMobile from "../../components/mobile/Header"
import Steps from '../../components/core/Steps'


export default {
  components: {
    cpnHeaderWeb: HeaderWeb,
    cpnHeaderMobile: HeaderMobile,
    cpnSteps: Steps
  },
  data() {
    return {
      loading_inicial: true,
      loading: false,
      estadosCivis: ["Solteiro(a)", "Casado(a)", "Separado(a)", "Divorciado(a)", "Viúvo(a)"],
      estadoCivil: "",
      profissao: "",
      profissaoRules: [value => !!value || "Por favor, informe a profissão!"],
      identidade: "",
      identidadeRules: [value => !!value || "Por favor, informe o numero de identidade!"],
      date_of_birth: "",
      gender: "",
      regimeDeBens: ["Parcial de bens", "Universal de bens", "Separação total", "Final nos aquestos"],
      comunhaoDeBens: "",
      alertMessage: "",
      isProd: false,
      isIdentidade: false,
      isGender: false,
      isDate_of_birth: false
    }
  },
  created() {
    this.isProd = process.env.NODE_ENV === "production"
    this.identidade = this.user.ID_document
    this.gender = this.user.gender
    this.date_of_birth = this.formatDate(this.user.date_of_birth)
    this.isIdentidade = this.user.ID_document ? true : false
    this.isGender = this.user.gender ? true : false
    this.isDate_of_birth = this.user.date_of_birth ? true : false
  },
  computed: {
    user() {
      return this.$store.getters["getUser"]
    },
    ...mapGetters(["getUser"])
  },
  methods: {
    formatDate(value) {
      if (!value) return ""
      const birthday = new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
      return moment(birthday).format("DD/MM/YYYY")
    },
    validate() {
      let campoCasadoPreenchido = false
      if (this.estadoCivil == "Casado(a)") {
        campoCasadoPreenchido = true
      }
      if (this.$refs.form.validate()) {
        this.loading = true
        if (campoCasadoPreenchido && this.comunhaoDeBens === undefined) {
          this.alertMessage = "*Por favor preencha sua comunhão de bens!"
          return false
        } else {
          this.alertMessage = ""
          let new_birthday = ""
          if (this.date_of_birth) {
            // console.log('birth: ', this.date_of_birth)
            const birth = this.date_of_birth.split("/")
            const day = birth[0]
            const month = birth[1]
            const year = birth[2]
            new_birthday = new Date(year, month - 1, day)
          } else {
            new_birthday = ""
          }
          const user = {
            ...this.user,
            profession: this.profissao,
            ID_document: this.identidade,
            gender: this.gender,
            date_of_birth: new_birthday,
            marital_status: this.estadoCivil,
            marital_type: this.comunhaoDeBens
          }

          this.$store.commit("storeUserState", user)

          this.storeUser(user)
            .then(new_user => {
              // store buyer_id, consumer_id, ... and the rest like email, name
              delete user.password
              const userToStoreLocal = {
                ...user,
                ...new_user.data.storeUser
              }
              localStorage.setItem("newUser", JSON.stringify(userToStoreLocal))
              if (this.isProd) {
                // analytics
                const analytics = {
                  eventCategory: "onboard",
                  eventAction: "CREATE-NEW-USER",
                  eventLabel: "CREATE-NEW-USER",
                  value: user.average_consumption
                }
                saveAnalytics(analytics)
              }
              this.processing = false
              // this.$router.push("cadastro-boas-vindas")
              this.$router.push("/cadastro/cartao")
              return true
            })
            .catch(error => {
              console.error("Erro ao criar usuário: ", error)
              return false
            })

          return true
        }
      }
    },

    // Quando seleciona estado civil reseta comunhao de bens
    deselecionaRegimeDeBens() {
      this.comunhaoDeBens = undefined
      this.alertMessage = ""
    },

    storeUser(user) {
      const group = "customer"
      return this.$apollo.mutate({
        // Query
        mutation: STORE_USER,
        // Parameters
        variables: {
          email: user.email,
          name: user.completeName || user.name || "",
          cpf: user.cpf,
          phone: user.phone,
          group: group,
          profession: user.profession,
          marital_status: user.marital_status,
          marital_type: user.marital_type,
          gender: user.gender || "",
          ID_document: user.ID_document,
          status: "QUALIFICAR",
          date_of_birth: user.date_of_birth,
          payment_type: user.payment_type || "credit_card",
          referral_balance: process.env.VUE_APP_REFERRAL_VALUE_INDICADO,
          referral_reward: process.env.VUE_APP_REFERRAL_VALUE_INDICADOR,
          password: user.password
        }
      })
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    }
  },
  mounted() {
    this.loading_inicial = false
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>
